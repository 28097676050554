




















import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src

@Component({
  components: {
    Header,
    SidebarPurchase,
  },
})
export default class Purchase extends Vue {
  
  mounted() {
  }
}
